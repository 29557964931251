import React, { useEffect, useState, useRef } from "react";
import { auth, db } from "../../services/firebaseconfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Loader from "../../components/Loader/Loader";
import { FaEdit } from "react-icons/fa";
import { PiRowsFill } from "react-icons/pi";
import toast from "react-hot-toast";

const Profile = () => {
  const [profileInfo, setProfileInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [emailData, setEmailData] = useState("");
  const [imgData, setImgData] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const placeholderImage = "/images/profile.png";

  const userInfo = async () => {
    const response = auth?.currentUser;
    const uid = response?.uid;
    const userRef = doc(db, "users", uid);
    try {
      setIsLoading(true);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setProfileInfo(userData);
        setEmailData(userData.email);
        console.log("User Data:", userData);
      } else {
        console.log("No such document!");
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching user data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const profileData = onAuthStateChanged(auth, (user) => {
      if (user) {
        userInfo(user?.uid);
      } else {
        console.log("No user is signed");
      }
    });
    return () => profileData();
  }, []);

  const handleImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImgData(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uid = auth.currentUser.uid;
    try {
      setIsLoading(true);
      if (emailData !== profileInfo.email) {
        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, { email: emailData });
      }
      setIsLoading(false);
      setIsDisabled(true);
      toast.success("Your Profile has been updated successfully!");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading ? <Loader /> : ""}
      <div className="contentSection">
        <div className="container mt-5">
          <form onSubmit={handleSubmit}>
            <div className="mb-5">
              <div className="d-flex aliggn-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-center gap-md-3 gap-1">
                  <div className="d-flex flex-column">
                    <div className="d-flex align=items-center justify-content-center">
                      <img
                        src={preview || placeholderImage}
                        alt="Profile"
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      {isDisabled ? (
                        ""
                      ) : (
                        <FaEdit
                          onClick={handleImage}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    <input
                      id="fileInput"
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      className="d-none"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="profileLabels">{profileInfo?.name}</div>
                    <div className="profileLabels1">{profileInfo?.email}</div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    type={isDisabled ? "submit" : "button"}
                    className="btn btn-edit"
                    onClick={() => {
                      setIsDisabled(!isDisabled);
                    }}
                  >
                    {isDisabled ? "Edit" : "Save"}
                  </button>
                </div>
              </div>
              <div className="mt-3">
                <div className="row mt-2">
                  <div className="col-md-5 col-12 mt-3">
                    <label className="form-label mb-2">Full Name</label>
                    <input
                      type="text"
                      disabled
                      className="form-control py-2"
                      placeholder="@example.com"
                      value={profileInfo?.name}
                    />
                  </div>
                  <div className="col-md-5 col-12 offset-md-2 offset-0 mt-3">
                    <label className="form-label mb-2">Date Of Birth</label>
                    <input
                      type="text"
                      disabled
                      className="form-control py-2"
                      placeholder="@example.com"
                      value={profileInfo?.dob}
                    />
                  </div>
                  <div className="col-md-5 col-12  mt-3 ">
                    <label className="form-label mb-2">Gender</label>
                    <select
                      className="form-select"
                      disabled
                      value={profileInfo?.gender}
                    >
                      <option value="" disabled selected>
                        Gender
                      </option>
                      <option value="option1">Male</option>
                      <option value="option2">Female</option>
                    </select>
                  </div>
                  <div className="col-md-5 col-12  offset-md-2 offset-0 mt-3">
                    <label className="form-label mb-2">Country</label>
                    <input
                      type="text"
                      value={profileInfo?.country}
                      disabled
                      className="form-control py-2"
                      placeholder="@example.com"
                    />
                  </div>
                  <div className="col-md-5 col-12  mt-3">
                    <label className="form-label mb-2">Mobile Number</label>
                    <input
                      type="number"
                      disabled
                      className="form-control py-2"
                      placeholder="09768709787"
                      value={profileInfo?.mobile}
                    />
                  </div>
                  <div className="col-md-5 col-12 mt-3  offset-md-2 offset-0">
                    <label className="form-label mb-2">Email</label>
                    <input
                      type="email"
                      required
                      disabled={isDisabled}
                      className="form-control py-2"
                      placeholder="johnwick101@gmail.com"
                      value={emailData}
                      onChange={(e) => {
                        setEmailData(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
