import React from "react";
import FeaturesSlider from "../../components/FeaturesSlider";

const Features = () => {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="container w-100 overflow-hidden mb-md-5 mb-0 mt-md-4 mt-1">
      <div className="slick-slider mt-sm-3 mt-5 mb-5 w-100">
        <FeaturesSlider settings={settings} />
      </div>
    </div>
  );
};
export default Features;
