import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import Connect from "../../components/Connect";
import Testimonial from "./Testimonial";
import { BiSolidOffer } from "react-icons/bi";


const PassengerDetails = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const { bus } = location.state || {};

  console.log(bus, "inside bus--");

  return (
    <div className="contentSection">
      <div className="container mt-3 pdHeader ">Review Your Booking</div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="border border-gray rounded">
              <div className="p-3 text-center">Traveller Details</div>
              <form>
                <div className="mt-3 p-2">
                  <div className="row">
                    <div className="col-6">
                      <input
                        style={{
                          border: "none",
                          borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                          borderRadius: "0",
                        }}
                        type="text"
                        className="form-control bg-gray"
                        placeholder="Name of Traveller"
                      />
                    </div>
                    <div className="col-6">
                      <input
                        style={{
                          border: "none",
                          borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                          borderRadius: "0",
                        }}
                        type="text"
                        className="form-control bg-gray"
                        placeholder="Age"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6">
                      <input
                        style={{
                          border: "none",
                          borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                          borderRadius: "0",
                        }}
                        type="text"
                        className="form-control bg-gray"
                        placeholder="Nationality"
                      />
                    </div>
                    <div className="col-6">
                      <select
                        className="form-select"
                        style={{
                          border: "none",
                          borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                          borderRadius: "0",
                        }}
                      >
                        <option value="" selected>
                          Gender
                        </option>
                        <option value="option1">Male</option>
                        <option value="option2">FeMale</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-5 text-center mb-4">
                    <button className="btn btn-secondary">Save</button>
                  </div>
                </div>
              </form>
            </div>

            <div className="mt-5">
              {[1, 2, 3, 4].map((item) => {
                return (
                  <div
                    className="p-3 mt-3"
                    style={{
                      borderRadius: "10px",
                      background: "rgba(250, 250, 250, 1)",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div>1. John Wick</div>
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ marginRight: "5px" }}
                      >
                        <div>
                          <MdOutlineModeEdit size={24} />
                        </div>
                        <div>
                          <MdDeleteOutline size={24} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-4">
              <button
                className="btn btn-secondary"
                onClick={() => setIsOpen(true)}
              >
                Select Seat
              </button>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div
              className="p-lg-4 p-md-3 p-3 mt-md-0 mt-4"
              style={{
                background: "rgba(250, 250, 250, 1)",
                borderRadius: "16px",
              }}
            >
              <div className="pdBooking">Boarding Details</div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="pdBooking1">{bus?.name}</div>
                <div className="pdBooking2">{bus?.type}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="bookingHead3">Nov 16</div>
                <div className="bookingHead3">Nov 17</div>
              </div>

              <div className="row mt-2">
                <div className="col-3">
                  <div className="d-flex flex-column">
                    <div className="pdBooking3">{bus?.time}</div>
                    <div className="pdBooking3">Okhla, New Delhi</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex flex-column">
                    <div className="text-center bookingHead3">
                      {bus?.duration}
                    </div>
                    <div className="dashBorder2 mt-1">
                      <div className="line2"></div>
                    </div>
                  </div>
                </div>
                <div className="col-3 text-end">
                  <div className="d-flex flex-column">
                    <div className="pdBooking3">{bus?.arrival}</div>
                    <div className="pdBooking3">Lucknow</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mt-4 p-md-4 p-3"
              style={{
                border: "2px dashed rgba(56, 152, 56, 1)",
                borderRadius: "20px",
              }}
            >
              <div className="pdOffer">Offers</div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <BiSolidOffer color="rgba(56, 152, 56, 1)" size={24} />
                  </div>
                  <div
                    className="d-flex align-items-center pdOffer1"
                    style={{ marginLeft: "2px" }}
                  >
                    50% off up to ₹100 | Use code BOOKNOW
                  </div>
                </div>
                <div className="pdOfferApply">Apply</div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <BiSolidOffer color="rgba(56, 152, 56, 1)" size={24} />
                  </div>
                  <div
                    className="d-flex align-items-center pdOffer1"
                    style={{ marginLeft: "2px" }}
                  >
                    20% off | Use code FIRSTTIME
                  </div>
                </div>
                <div className="pdOfferApply">Apply</div>
              </div>
            </div>

            <div className="p-4 border border-gray rounded mt-4">
              <div className="row">
                <div className="col-5">
                  <div className="d-flex align-items-center ">
                    <div>
                      <BiSolidOffer size={26} color="rgba(56, 152, 56, 1)" />
                    </div>
                    <div className="pdBooking1" style={{ marginLeft: "10px" }}>
                      Apply Code
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <input
                    style={{
                      border: "none",
                      borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
                      borderRadius: "0",
                    }}
                    type="text"
                    className="form-control bg-gray"
                    placeholder="Enter Code"
                  />
                </div>
              </div>
            </div>

            <div className="p-md-4 p-3 border border-gray rounded mt-4">
              <div className="pdBooking1">Bill Details</div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="pdBill">Base Ticket Fare</div>
                  <div className="pdBill">₹1000.00</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="pdBill">Total Travellers</div>
                  <div className="pdBill">4</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="pdBill">CGST & SGST</div>
                  <div className="pdBill">₹700.00</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="pdBooking1">Total Charge</div>
                  <div className="pdBooking1">₹4700.00</div>
                </div>
              </div>
            </div>

            <div className="container text-center pdBill mt-4">
              {" "}
              Discounts, offers and price concessions will be applied later
              during payment
            </div>
            <div className="mt-3">
              <button className="btn btn-secondary w-100">
                Make a Payment
              </button>
            </div>
            <div
              className="text-center mt-4"
              style={{
                color: "rgba(255, 96, 96, 1)",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-2 container">
        <Connect title={"Facing an issue? Request A Call Back !"} />
      </div>
      <div className="mt-5 mb-5">
        <Testimonial />
      </div>
    </div>
  );
};

export default PassengerDetails;
