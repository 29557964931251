import React from "react";
import Gallery from "react-photo-gallery";

const GalleryImage = () => {
  const images = [
    {
      src: "/images/bus3.png",
      width: 593,
      height: 288,
    },
    {
      src: "/images/bus2.png",
      width: 181,
      height: 288,
    },
    {
      src: "/images/bus4.png",
      width: 317,
      height: 144,
    },
    {
      src: "/images/bus5.png",
      width: 320,
      height: 444,
    },
    {
      src: "/images/bus6.png",
      width: 317,
      height: 288,
    },
    {
      src: "/images/bus7.png",
      width: 358,
      height: 144,
    },
    {
      src: "/images/bus8.png",
      width: 403,
      height: 262,
    },
    {
      src: "/images/bus9.png",
      width: 403,
      height: 172,
    },
    {
      src: "/images/bus10.png",
      width: 449,
      height: 288,
    },
    {
      src: "/images/bus11.png",
      width: 558,
      height: 288,
    },
  ];

  const imageRenderer = ({ photo }) => {
    return (
      <div
        style={{
          width: `${photo.width}px`,
          height: `${photo.height}px`,
          padding: "5px",
          boxSizing: "border-box",
        }}
      >
        <img
          src={photo.src}
          style={{
            borderRadius: "15px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt=""
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="container  mt-md-5 mt-3">
        <div className="d-flex align-items-center justify-content-center mt-3">
          <div className="galleryHeader" style={{ fontWeight: 600 }}> Meet Our Eco-Friendly Buses</div>
        </div>
      </div>

      <div className="gallery-container mt-5 mb-5">
        <div className="mt-4">
          <Gallery photos={images} renderImage={imageRenderer} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default GalleryImage;
