import React, { useState } from "react";
import { format, addDays, subDays } from "date-fns";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";

const DateSlider = () => {
  // State to keep track of the current date (today's date)
  const [currentDate, setCurrentDate] = useState(new Date());

  // Helper function to get an array of dates
  const getDates = (date) => {
    return [
      subDays(date, 2), // Two days before
      subDays(date, 1), // One day before
      date, // Current date
      addDays(date, 1), // One day after
      addDays(date, 2), // Two days after
    ];
  };

  // Handler functions for the buttons
  const handlePrevious = () => {
    setCurrentDate((prevDate) => subDays(prevDate, 1));
  };

  const handleNext = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  // Generate dates for display
  const dates = getDates(currentDate);

  return (
    <div
      className=" w-100 d-flex align-items-center justify-content-between "
      style={{
        overflowX: "hidden",
        maxWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <div onClick={handlePrevious} className="">
        <FiChevronLeft color="black" size={24} />
      </div>

      {/* Date Display Container */}
      <div className="d-flex align-items-center gap-lg-2 gap-md-5 gap-sm-4 gap-3 ">
        {dates.map((date, index) => (
          <div
            style={{
              border: "1px solid white",
              fontSize: "14px",
              padding: "5px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor:
                date.getDate() === currentDate.getDate()
                  ? "rgba(23, 255, 23, 1)"
                  : "#ffff",
              fontWeight: date.getDate() === currentDate.getDate() ? 700 : 500,
            }}
            key={index}
            className={`d-flex flex-column p-1 text-center ${
              date.getDate() === currentDate.getDate() ? "font-weight-bold" : ""
            }`}
          >
            <div>{format(date, "EE")}</div>
            <div className="">{format(date, "dd MMM")}</div>
          </div>
        ))}
      </div>

      <div className="" style={{ cursor: "pointer" }} onClick={handleNext}>
        <FiChevronRight color="black" size={24} />
      </div>
    </div>
  );
};

export default DateSlider;
