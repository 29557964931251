import React from "react";
import FeaturesSlider from "../../components/FeaturesSlider";
import Connect from "../../components/Connect";
import DateSlider from "../../components/DateSlider";
import Testimonial from "./Testimonial";
import BusCard from "../../components/BusCard";
import { useNavigate } from "react-router-dom";

const Bookings = () => {
  const busData = [
    {
      id: 1,
      name: "Leafy Bus",
      type: "AC Chair Economy",
      price: 785,
      time: "5:31 AM",
      duration: "1h52m",
      arrival: "07:23 AM",
      busNo: "DL 1PC 5445",
      seatsLeft: 2,
    },
    {
      id: 2,
      name: "Blue Bird",
      type: "Non-AC Economy",
      price: 500,
      time: "6:00 AM",
      duration: "2h30m",
      arrival: "08:30 AM",
      busNo: "DL 2PC 5446",
      seatsLeft: 5,
    },
    {
      id: 3,
      name: "Rapid Bus",
      type: "Luxury AC",
      price: 1200,
      time: "7:00 AM",
      duration: "1h20m",
      arrival: "08:20 AM",
      busNo: "DL 3PC 5447",
      seatsLeft: 1,
    },
  ];

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };

  const navigate = useNavigate();

  const busClick = (bus) => {
    navigate("/passenger-leafybus", { state: { bus } });
  };

  return (
    <div className="contentSection">
      <div className="bookingBanner mt-md-4 mt-1">
        <div className="row">
          <div className="col-md-6 col-4"></div>
          <div className="col-md-6 col-8">
            <div className="p-3 d-flex flex-column">
              <div className="">Bus Booking</div>
              <div className="contactText ">Book Your Leafy Bus</div>
              <div className="contactText ">Now</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-md-5 mt-5">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="d-flex align-items justify-content-between gap-3">
              <select
                className="form-select py-md-3 py-2  w-100 "
                style={{ backgroundColor: "rgba(232, 232, 232, 1)" }}
                required
              >
                <option value="" selected>
                  Source Location
                </option>

                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
              <select
                className="form-select py-md-3 py-2  w-100 "
                style={{ backgroundColor: "rgba(232, 232, 232, 1)" }}
                required
              >
                <option value="" selected>
                  Source Location
                </option>

                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
            </div>
            <div className="mt-3 w-100">
              <DateSlider />
            </div>
            <div className="mt-3">
              <button className="btn btn-secondary w-100">
                Search For Buses
              </button>
            </div>
            <div className="mt-3">
              <FeaturesSlider settings={settings} />
            </div>

            <div className="mt-3">
              <div className="text-start">
                Discover a seamless way to plan and book your bus with us.
                Whether you're traveling across the city or exploring new
                destinations, our platform offers a user-friendly experience
                designed to make your travel booking easy, fast, and secure.
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-0 col-0"></div>
          <div className="col-lg-7 col-md-12 col-12">
            {busData?.map((item) => {
              return (
                <div
                  className="d-flex flex-column mt-3"
                  key={item?.id}
                  onClick={() => busClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <BusCard data={item} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-5 mb-2">
          <Connect title={"Request A Call Back !"} />
        </div>
      </div>
      <div className="mt-5 mb-5">
        <Testimonial />
      </div>
    </div>
  );
};

export default Bookings;
