import React from "react";
import "../pages/HomePage/Common.css";

const BusCard = ({ data }) => {
  return (
    <div
      className="shadow-card p-md-4 p-sm-3 p-2"
      style={{ borderRadius: "15px" }}
    >
      <div className="d-flex  justify-content-between">
        <div className="d-flex flex-column">
          <div className="bcHeader">{data.name}</div>
          <div className="bcHeader1">{data.type}</div>
        </div>
        <div className="bcHeader2">₹{data.price}</div>
      </div>

      <div className="row mt-4 align-items-center">
        <div className="col-1">
          <button className="btn-eta">ETA</button>
        </div>
        <div className="col-11">
          <div className="row align-items-center ">
            <div className="col-2 bcHeader3">{data.time}</div>
            <div className="col-3 dashBorder">
              <div className="line"></div>
            </div>
            <div className="col-2 text-center bcHeader1">{data.duration}</div>
            <div className="col-3 dashBorder1">
              <div className="line1"></div>
            </div>
            <div className="col-2 bcHeader4">{data.arrival}</div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between mt-4">
        <div className="bcHeader1">{data.busNo}</div>
        <div className="bcHeader5">{data.seatsLeft} Seat Left</div>
      </div>
    </div>
  );
};

export default BusCard;
