import React from "react";

const AppScreen = () => {
  return (
    <div className="appInfoBanner">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex aligm-items-center justify-content-lg-end justify-content-center p-xl-5 p-md-3 p-4">
          <div className="d-flex flex-column gap-2  justify-content-center">
            <div>
              <p className="">DOWNLOAD OUR BUS BOOKING APP</p>
            </div>
            <div>
              <h2 className="">SEAMLESS TRAVEL AT YOUR </h2>
              <h2 className="">FINGERTIPS</h2>
            </div>
            <div className="mt-4">
              <ul style={{ fontWeight: 400 }}>
                <li className="mb-2">
                  Instant Bookings: Reserve your bus tickets in just a few taps.
                </li>
                <li className="mb-2">
                  Real-Time Updates: Get live updates on your bus schedule and
                  seat availability.
                </li>
                <li className="mb-2">
                  Secure Payment: Enjoy safe and easy payment options.
                </li>
                <li>
                  User-Friendly Interface: Navigate effortlessly with our
                  intuitive design.
                </li>
              </ul>
              <div className="d-flex align-items-center">
                <img
                  src="/images/appImage.png"
                  alt="appImage"
                  className=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-md-start justify-content-center">
          <div className="d-flex align-items-center justify-content-lg-start justify-content-center">
            <img
              src="/images/mobileImage1.png"
              alt="appImage"
              className=""
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppScreen;
