import axios from "axios";
import CryptoJS from "crypto-js";
import { API, baseUrl } from "./endpoint";

const generateXToken = () => {
  const date = new Date().toISOString().split("T")[0].replace(/-/g, "/");

  let leaf = "leaf";
  let key = "EGXK1DYMIPDYFILUSGNSZY12JZ4FNGM4";
  const data = `${leaf}|${key}|${date}`;

  const passphrase = "pass";
  const aesKey = CryptoJS.SHA256(passphrase).toString(CryptoJS.enc.Hex);
  const keyBytes = CryptoJS.enc.Hex.parse(aesKey);
  const iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

  // Encrypt data using AES
  const encrypted = CryptoJS.AES.encrypt(data, keyBytes, { iv: iv }).toString();

  // Convert Base64 to Base64 URL encoding and remove padding
  const base64UrlToken = encrypted
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return base64UrlToken;
};

const headerToken = generateXToken();
export const getDestinations = async () => {
  return await axios.get(`${baseUrl}${API.Allapi.getDestinations}`, {
    headers: {
      "X-TOKEN": headerToken,
    },
  });
};

export const getRoutes = async (id1, id2, date) => {
  return await axios.get(
    `${baseUrl}${API.Allapi.getRoutes}/${id1}/${id2}/${date}.json`,
    {
      headers: {
        "X-TOKEN": headerToken,
      },
    }
  );
};
