import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";

const FeatureModal = ({ toggle, selectedCard, isModalOpen }) => {
  console.log(selectedCard, "info----");

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        toggle={toggle}
        centered
        // style={{ borderRadius: "20px", overflow: "hidden" }}
      >
        <ModalBody>
          <div className="d-flex flex-column align-items-center container">
            <img
              src={selectedCard?.image2}
              alt={selectedCard?.text1}
              width="100%"
            />
            <div className="mt-2">{selectedCard?.text1}</div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FeatureModal;
