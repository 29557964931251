import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { MdContentCopy } from "react-icons/md";
import { MdClose } from "react-icons/md";

const OfferModal = ({ IsOpen, setIsOpen, data }) => {
  const toggle = () => {
    setIsOpen(!IsOpen);
  };

  return (
    <React.Fragment>
      <Modal isOpen={IsOpen} toggle={toggle} centered size="lg">
        <ModalBody
          style={{ padding: 0, overflow: "hidden", borderRadius: "16px" }}
        >
          <div style={{ margin: 0, padding: 0 }}>


            <div
              className="row m-0 p-md-3 p-1"
              style={{
                background: "rgba(243, 243, 243, 1)",
                borderRadius: "16px",
              }}
            >
              <div className="text-end mt-0" onClick={toggle} style={{ cursor: "pointer" }}>
                <MdClose />
              </div>
              <div className="col-lg-7 col-12 p-0">
                <img
                  src={data.image}
                  alt="Offer"
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "16px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="col-lg-5 col-12 p-3 text-center">
                <div className="d-flex flex-column">
                  <div style={{ fontSize: "24px", fontWeight: 400 }}>
                    Get {data?.discount} Off up to
                  </div>
                  <div
                    style={{
                      fontSize: "48px",
                      fontWeight: 700,
                      marginTop: "5px",
                    }}
                  >
                    ₹{data.dscAmt}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
                  <div className="clipBackground">
                    <div style={{ fontSize: "16px", fontWeight: 600 }}>
                      {data.code}
                    </div>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <MdContentCopy
                      size={40}
                      onClick={() => {
                        navigator.clipboard.writeText(data?.code);
                        alert(`${data.code} Code Copied`);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 p-lg-5 p-2">
              <div className="col-lg-5 col-12 tnC">
                <div className="mt-lg-5 mt-3">
                  <div className="tncHead">
                    Terms & Conditions:
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12 mt-3">
                <ol>
                  <li style={{ fontSize: "16px", fontWeight: 400 }}>
                    Please Note that your Ticket Amount has to be{" "}
                    <span style={{ fontSize: "16px", fontWeight: 600 }}>
                      greater than ₹250
                    </span>
                  </li>
                  <li
                    className="mt-3"
                    style={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    This Coupon is only{" "}
                    <span style={{ fontSize: "16px", fontWeight: 600 }}>
                      valid on your 1st Booking with Leafybus
                    </span>
                  </li>
                  <li
                    className="mt-3"
                    style={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    For further details, refer T&C on our website
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OfferModal;
