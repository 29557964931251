import "./App.css";
import "./pages/HomePage/Common.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";

import { Toaster } from "react-hot-toast";

function App() {
  return (
    <React.Fragment>
     
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <Toaster
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}

export default App;
