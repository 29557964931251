import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseconfig";

export const UserExistence = async (userID) => {
  try {
    const userRef = doc(db, "users", userID); // Document reference
    const userDoc = await getDoc(userRef); // Get the document
    return userDoc.exists(); // Check if the document exists
  } catch (error) {
    console.error("Error checking user existence:", error); // Log the error
    return false; // Return false if there is an error
  }
};
