import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import FeatureModal from "./FeatureModal";
import Login from "../pages/Auth/Login";
import { BookingModal } from "../pages/HomePage/ManageBooking";
import { useNavigate } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { FiChevronDown } from "react-icons/fi";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getDestinations, getRoutes } from "../services/allApi";
import toast from "react-hot-toast";

const Header = ({ mode }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [bookingModal, setBookingModal] = useState(false);
  const [uniqueLoc, setUniqueLoc] = useState([]);

  const [selectedDate, setSelectedDate] = useState("");
  const [origin, setOrigin] = useState("");
  const [dest, setDest] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const cardData = [
    {
      id: 1,
      image: "/images/womenSafety.png",
      text: "Women Safety",
      image2: "/images/modal2.png",
      text1:
        "Top-rated for women's safety, ensuring a secure and comfortable journey.",
    },
    {
      id: 2,
      image: "/images/bus1.png",
      text: "On Time Gurantee",
      image2: "/images/modal1.png",
      text1:
        "The staff is trained and makes the best effort to help you reach your destination on time, safely.",
    },
    {
      id: 3,
      image: "/images/time.png",
      text: "Top Notch cleanliness",
      image2: "/images/modal3.png",
      text1:
        "Leafy Bus ensures a pristine travel experience, with top-rated cleanliness & dedicated care for every passenger.",
    },
  ];
  const handleCard = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };
  const toggle = () => {
    setIsModalOpen(false);
    setSelectedCard(null);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getDestinations();
      const locationInfo = response?.data?.flatMap((item) => [
        { id: item?.origin?.id, name: item?.origin?.name },
        { id: item?.destination?.id, name: item?.destination?.name },
      ]);
      const uniqueLocations = locationInfo.filter(
        (location, index, self) =>
          index === self.findIndex((loc) => loc.id === location.id)
      );
      setUniqueLoc(uniqueLocations);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while getting the destinations", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleOrigin = (e) => {
    const res = e.target.value;

    if (res != dest) {
      setOrigin(res);
    } else {
      toast.error("Origin can't be same as Destination");
      return;
    }
  };

  const handleDest = (e) => {
    const res = e.target.value;

    if (res != origin) {
      setDest(res);
    } else {
      toast.error("Destination can't be same as Origin");
      return;
    }
  };

  const handleRoute = async (e) => {
    e.preventDefault();

    try {
      let id1 = origin;
      let id2 = dest;
      let date = selectedDate;
      console.log(id1, id2, date);
      const response = await getRoutes(id1, id2, date);
      console.log(response, "searching for buses------");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {mode === "two" ? (
        <NavBar
          setIsOpen={setIsOpen}
          mode={mode}
          setBookingModal={setBookingModal}
        />
      ) : (
        <div className="headerContainer">
          <div className="headerBanner">
            <div className="container">
              <NavBar
                setIsOpen={setIsOpen}
                mode={mode}
                setBookingModal={setBookingModal}
              />

              <div className="d-flex gap-4  featureCard text-white ">
                {cardData.map((card) => (
                  <div
                    key={card.id}
                    className=" mainCard hover-card  d-flex flex-column align-items-center justify-content-center mb-1"
                    style={{ borderRadius: "20px", cursor: "pointer" }}
                    onClick={() => handleCard(card)}
                  >
                    <img
                      src={card.image}
                      className="cardLogo mt-2"
                      alt={`Card ${card.id}`}
                      width="100%"
                    />
                    <div className="cardText text-center d-flex align-items-center justify-content-center mb-lg-3 mb-md-1 mb-sm-0 mb-0">
                      {card.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="container">
            <form onSubmit={handleRoute}>
              <div className="bottomContainer">
                <div className="row w-100 align-items-center">
                  <div className="col-md-3 col-sm-6 col-12 bg-gray">
                    <select
                      className="form-select py-md-3 py-2   w-100 "
                      style={{
                        backgroundColor: "rgba(232, 232, 232, 1)",
                        cursor: "pointer",
                      }}
                      required
                      onChange={handleOrigin}
                      value={origin}
                    >
                      <option value="" disabled selected>
                        Source Location
                      </option>
                      {uniqueLoc?.map((item, index) => {
                        return (
                          <>
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className=" col-md-3 col-sm-6 col-12 mt-md-0 mt-sm-0 mt-3">
                    <select
                      className="form-select py-md-3 py-2  w-100"
                      style={{
                        backgroundColor: "rgba(232, 232, 232, 1)",
                        cursor: "pointer",
                      }}
                      required
                      value={dest}
                      onChange={handleDest}
                    >
                      <option value="" disabled selected>
                        Destination
                      </option>
                      {uniqueLoc?.map((item, index) => {
                        return (
                          <>
                            <option key={index} value={item?.id}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 mt-md-0 mt-3">
                    <input
                      type="date"
                      className="form-control  py-md-3 py-2  w-100"
                      required
                      value={selectedDate}
                      onChange={(e) => {
                        setSelectedDate(e.target.value);
                      }}
                      placeholder="Select Date"
                      style={{
                        backgroundColor: "rgba(232, 232, 232, 1)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-12 mt-md-0 mt-3">
                    <button
                      className="btn btn-search text-black w-100 custom-button d-flex align-items-center justify-content-center"
                      type="submit"
                      // onClick={() => {
                      //   navigate("/booking-leafybus");
                      // }}
                    >
                      Search Bus
                      <CiSearch
                        size={22}
                        className=""
                        style={{
                          marginLeft: "5px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {isModalOpen ? (
        <FeatureModal
          toggle={toggle}
          selectedCard={selectedCard}
          isModalOpen={isModalOpen}
        />
      ) : (
        ""
      )}
      <Login isOpen={isOpen} setIsOpen={setIsOpen} />

      <BookingModal isOpen={bookingModal} setIsOpen={setBookingModal} />
    </React.Fragment>
  );
};

export default Header;

export const NavBar = ({ setIsOpen, mode, setBookingModal }) => {
  const isLoggedIn = localStorage.getItem("userID");
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <React.Fragment>
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-white shadow 
          ${
            mode === "two"
              ? "p-xl-3 p-lg-2 p-md-2 p-sm-1 p-0 mt-0 fixed-top"
              : "p-xl-3 p-lg-2 p-md-2 p-sm-1 p-0 mt-md-3 mt-2 "
          }  rounded`}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="logo.png" alt="logo" className="mainLogo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              padding: "0.25rem 0.25rem",
              fontSize: "1rem",
            }}
          >
            <span
              className="navbar-toggler-icon"
              style={{
                width: "1rem",
                height: "1rem",
              }}
            ></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item">
                <a
                  className="nav-link active mx-2"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active mx-2"
                  href="#"
                  onClick={() => {
                    setBookingModal(true);
                  }}
                >
                  Manage Bookings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active mx-2" href="#">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active mx-2" href="/gallery-leafybus">
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active mx-2 "
                  href="/contact"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            {isLoggedIn ? (
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  tag="span"
                  className="d-flex align-items-center dropdown-toggle  no-caret"
                  caret={false}
                  style={{ cursor: "pointer" }}
                >
                  <LuUser2 className="me-2" /> My Account
                  <FiChevronDown className="ms-1" />{" "}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    href="/profile-leafybus"
                    className="dropdownBorder"
                  >
                    Profile
                  </DropdownItem>
                  <DropdownItem href="/" className="dropdownBorder">
                    Settings
                  </DropdownItem>
                  <DropdownItem onClick={() => localStorage.clear()}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
              <button
                className="btn btn-primary text-black"
                onClick={() => setIsOpen(true)}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};
